import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Button, Collapse } from 'reactstrap';
import { observer } from 'mobx-react';
import { Element as ScrollTo, scroller } from 'react-scroll';

import { Keys } from 'Translation/Setup';
import app from 'State/AppService';
import workflow from 'State/WorkflowService';
import errorHandler from 'State/ErrorHandler';

import DefaultLayout from 'Components/Workflow/DefaultLayout';
import Cart from 'Components/Workflow/Cart';
import Headline from 'Components/Workflow/Headline';
import Nav from 'Components/Workflow/Nav';

import { AddonTrain } from 'Models/UI/AddonTrain';
import { PayloadTrain } from 'Models/UI/PayloadTrain';

import { railFlyState, RailFlyState } from './RailFlyState';
import styles from './RailFly.module.scss';

import { Info } from '../Train/Components/Info';
import { Box } from '../Train/Components/Box';
import iconDB from '../Train/Icons/db.png';
// import { TrainConnection } from '../Train/Components/TrainConnection';
import { RailFlySelection } from './Components/RailFlySelection';
import Breadcrumbs from '../../../Components/Layouts/Parts/Breadcrumbs';
import { TiArrowRight } from 'react-icons/ti';
import { withTheme } from '../../../Context/withTheme';
import { ThemeProps } from '../../../Context/AppContext';
import {useLanguage} from "../../../Helpers/useLanguage";
// import { TrainTariff } from '../Train/Components/TrainTariff';

const RailFly = observer((props: ThemeProps) => {
  const state = useContext<RailFlyState>(railFlyState);

  const { t, i18n } = useTranslation();
  const iframeSrcLanguage = useLanguage();

  const scrollElementPrefix = 'scrollElement_';

  useEffect(() => {
    if (
      workflow.isCurrentStepExcluded ||
      (workflow.booking && workflow.booking.ritMode !== 'RNF') ||
      props.theme === 'pepxcite'
    ) {
      workflow.excludeCurrentStep();
      workflow.navigateToNextStep(true);
      return;
    }
    app.trackTimeLoadStarted(workflow.currentStep!);
    workflow
      .loadStep()
      .then(({language}) => {
        const globalLanguage = iframeSrcLanguage || language;
        if (!!globalLanguage) {
          i18n.changeLanguage(globalLanguage);
        }
        state.readyNow();
        app.deactivateLoader();
      })
      .then(() => app.trackTimeLoadFinished(workflow.currentStep!))
      .then(() => {
        app.tracking.addPageAndUser(workflow.booking);
        if (workflow.currentStepIndex === 1) {
          app.tracking.sendBeginCheckoutEvent(workflow.booking, workflow.currentStepTrackingCustomEvent, workflow.getAvailableAdditionalServicesTypes);
        } else {
          app.tracking.sendCheckoutStepEvent(workflow.booking, workflow.currentStepTrackingCustomEvent, workflow.getAvailableAdditionalServicesTypes);
        }
      })
      .then(() => {
        console.log('STEPINDEX', workflow.currentStepIndex)
        if (state.fly) {
          toggleItem(state.fly as AddonTrain);
        } else if (state.selectedItems.length > 0) {
          toggleItem(state.selectedItems[0] as AddonTrain);
        } else {
          toggleItem(state.none as AddonTrain);
        }
      })
      .then(() => window.scrollTo(0, 0))
      .catch(e => errorHandler.catchError(e));

    getTrainStation();
    getFlightStation();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Breadcrumbs next={next} />
      <DefaultLayout
        top={renderTop()}
        left={renderLeft()}
        right={renderRight()}
        bottom={renderBottom()}
      />
    </>
  );

  function fetchTrainStation(): Promise<any> {
    const myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');
    myHeaders.append('Content-Type', 'application/json; charset=utf-8');

    const myInit = {
      method: 'GET',
      headers: myHeaders
    };
    return fetch('/trainStationLand_RNF.json', myInit);
  }

  async function getTrainStation() {
    let response = await fetchTrainStation();
    const trainStations = await response.json();
    state.setTrainStations(trainStations);
  }

  function fetchFlightStation(): Promise<any> {
    const myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');
    myHeaders.append('Content-Type', 'application/json; charset=utf-8');

    const myInit = {
      method: 'GET',
      headers: myHeaders
    };
    return fetch('/trainStationFlight_RNF.json', myInit);
  }

  async function getFlightStation() {
    let response = await fetchFlightStation();
    const flightStations = await response.json();
    state.setFlightStations(flightStations);
  }

  function hasError(train: AddonTrain) {
    const itemError = state.itemErrorMessages.hasOwnProperty(train.id)
      ? state.itemErrorMessages[train.id]
      : false;
    return !!itemError;
  }

  // function getError(train: AddonTrain) {
  //   const itemError = state.itemErrorMessages.hasOwnProperty(train.id)
  //     ? state.itemErrorMessages[train.id]
  //     : "";
  //   return itemError;
  // }

  function renderLeft() {
    return (
      <div className={styles.container}>
        {/* <p>
          <strong>{t(Keys.WORKFLOW.RailFly.intro.title)}</strong>
        </p> */}
        <p>
          <Trans i18nKey={Keys.WORKFLOW.RailFly.intro.text}></Trans>
        </p>

        <div className={styles.trains}>
          {state.fly && (
            <React.Fragment>
              {state.showValidation && (
                <div className={styles.errorContainer}>
                  <ScrollTo name={scrollElementPrefix + state.fly.id}>
                    <Alert color="danger" isOpen={state.showValidation && hasError(state.fly)}>
                      {t(Keys.WORKFLOW.RailFly.itemError)}
                      <div className={styles.small}>{/* <div>{getError(state.spar)}</div> */}</div>
                    </Alert>
                  </ScrollTo>
                </div>
              )}

              <Box
                item={state.fly}
                isSelected={isTrainSelected(state.fly)}
                selectedItem={state.selectedTrain}
                label={t(Keys.WORKFLOW.RailFly.fly.label)}
                toggle={toggleItem}
              >
                <Info url={state.fly.infoURL} />

                <div className={styles.trainDescription} onClick={() => toggleItem(state.fly!)}>
                  <h5>{t(Keys.WORKFLOW.RailFly.railFly.headline)}</h5>
                  {state.fly.descriptionDetail && (
                    <div className={styles.trainDescriptionHtml}>
                      <div className={styles.bulletHeadline}>
                        {t(Keys.WORKFLOW.RailFly.railFly.step1.headline)}
                      </div>
                      <div className={styles.bulletPoint}>
                        {t(Keys.WORKFLOW.RailFly.railFly.step1.point1)}
                      </div>
                      <div className={styles.bulletPoint}>
                        {t(Keys.WORKFLOW.RailFly.railFly.step1.point2)}
                      </div>
                      <div className={styles.bulletHeadlineMargin}>
                        {t(Keys.WORKFLOW.RailFly.railFly.step2.headline)}
                      </div>
                      <div className={styles.bulletPoint}>
                        {t(Keys.WORKFLOW.RailFly.railFly.step2.point1)}
                      </div>
                    </div>
                  )}
                  <Collapse isOpen={isTrainSelected(state.fly)}>
                    <div className={styles.trainConnection}>
                      <RailFlySelection
                        onChangeDepartingStationCode={value => {
                          state.setDepartingStationCode(value);
                          onChangeTrainSelection();
                        }}
                        onChangeDepartingClassCode={value => {
                          state.setDepartingClassCode(value);
                          onChangeTrainSelection();
                        }}
                        onChangeDestinationCode={value => {
                          state.setDestinationCode(value);
                          onChangeTrainSelection();
                        }}
                        onChangeReturningDepartureCode={value => {
                          state.setReturningDepartureCode(value);
                          onChangeTrainSelection();
                        }}
                        onChangeReturningStationCode={value => {
                          state.setReturningStationCode(value);
                          onChangeTrainSelection();
                        }}
                        onChangeReturningClassCode={value => {
                          state.setReturningClassCode(value);
                          onChangeTrainSelection();
                        }}
                        onChangeDirection={value => {
                          state.setDirection(value);
                          onChangeTrainSelection();
                        }}
                        departingStationCode={state.departingStationCodeLabel}
                        departingClassCode={state.departingClassCode}
                        returningStationCode={state.returningStationCodeLabel}
                        returningClassCode={state.returningClassCode}
                        destination={state.destination}
                        destinationLabel={state.destinationLabel}
                        returningDeparture={state.returningDepartureLabel}
                        origin={state.origin}
                        trainConnections={state.trainStations}
                        flightConnections={state.flightStations}
                        direction={state.direction}
                      />
                      
                      {/* {!!state.selectedTrain &&
                          (state.selectedTrain.departingSelection.hasStepOne ||
                              state.selectedTrain.returningSelection.hasStepOne) && (<>
                            <TrainConnection
                                id={'train'}
                                direction={state.direction}
                                departingTrains={state.departingTrains}
                                returningTrains={state.returningTrains}
                                destination={state.destination}
                                destinationLabel={state.destinationLabel}
                                departureDate={state.departureDate}
                                departingStation={state.departingStationCodeLabel}
                                returningStation={state.returningStationCodeLabel}
                                returningDate={state.arrivalDate}
                                selectedDepartingTrain={state.departingTrain}
                                selectedDepartingSearchHour={state.departingSearchHour}
                                selectedReturningSearchHour={state.returningSearchHour}
                                onChangeDepartingTrain={value => {
                                  state.setDepartingTrain(value);
                                  onChangeTrainConnection();
                                }}
                                onChangeDepartingSearchHour={value => {
                                  state.setDepartingSearchHour(value);
                                  onChangeTrainConnection();
                                }}
                                selectedReturningTrain={state.returningTrain}
                                onChangeReturningTrain={value => {
                                  state.setReturningTrain(value);
                                  onChangeTrainConnection();
                                }}
                                onChangeReturningSearchHour={value => {
                                  state.setReturningSearchHour(value);
                                  onChangeTrainConnection();
                                }}
                            />
                            {state.bundles &&
                                <TrainTariff
                                    bundles={state.bundles}
                                    selectedBundle={state.selectedBundle}
                                    onChangeSelectedBundle={bundle => {
                                      state.setSelectedBundle(bundle);
                                      onChangeTrainConnection();
                                    }}
                                />}
                          </>)} */}
                    </div>
                    <div className={styles.seperator}></div>
                  </Collapse>
                </div>
              </Box>
            </React.Fragment>
          )}

          {state.none && (
            <Box
              selectedItem={null}
              item={state.none}
              isSelected={isTrainSelected(state.none)}
              label={t(Keys.WORKFLOW.RailFly.none.label)}
              toggle={toggleItem}
            >
              <div className={styles.trainDescription} onClick={() => toggleItem(state.none!)}>
                <h5>{t(Keys.WORKFLOW.RailFly.none.description)}</h5>
              </div>
            </Box>
          )}
        </div>
      </div>
    );
  }

  function renderTop() {
    return (
      <div className={'d-flex justify-content-between'}>
        <div className={styles.headlineContainer}>
          <div className={styles.headlineText}>
            <Headline text={t(Keys.WORKFLOW.RailFly.headline)} />
          </div>
          <div className={styles.headlineImageContainer} title={'Deutsche Bahn'}>
            <img className={styles.headlineImage} alt={'Deutsche Bahn'} src={iconDB} />
          </div>
        </div>
        <div className={styles.topNav}>
          <Button
            color="primary"
            onClick={next}
            tabIndex={0}
            onKeyPress={e => e.key === 'Enter' && next()}
            className={'w-100'}
            block
          >
            <div>
              <span>{t(Keys.COMPONENTS.Nav.next)}</span>
              <TiArrowRight />
            </div>
          </Button>
        </div>
      </div>
    );
  }

  function renderRight() {
    return <Cart bookingOnRequest={workflow.bookingOnRequest} />;
  }

  function renderBottom() {
    return (
      <>
        <Alert color="danger" isOpen={state.showValidation && state.showSelectionErrorMessages}>
          {t(Keys.WORKFLOW.RailFly.selectionError)}
        </Alert>
        <Alert color="danger" isOpen={state.showValidation && state.hasItemErrors}>
          {t(Keys.WORKFLOW.RailFly.globalFormError)}
        </Alert>

        <Alert color="danger" isOpen={state.showValidation && state.apiErrorMessage !== null}>
          <strong>{t(Keys.WORKFLOW.RailFly.apiError)} </strong>
          <br />
          {state.apiErrorMessage}
        </Alert>

        {!state.isFirstStep && (
          <Nav onPrev={() => workflow.navigateToPrevStep()} onNext={() => next()} />
        )}
        {state.isFirstStep && (
          <Nav onCloseWindow={() => window.top?.close()} onNext={() => next()} />
        )}
      </>
    );
  }

  function scrollToFirstError() {
    if (state.hasItemErrors) {
      scroller.scrollTo(scrollElementPrefix + state.firstErrorId, {
        duration: 750,
        smooth: true,
        offset: -30
      });
    }
  }

  async function next() {
    state.updateShowValidation(true);

    state.validateSelectedItems();

    if (state.hasItemErrors || state.showSelectionErrorMessages) {
      state.updateShowValidation(true);
      window.setTimeout(scrollToFirstError, 100); //TODO fix this shit
      return;
    }

    app.activateLoader();

    try {
      await saveStep();
      workflow.navigateToNextStep();
    } catch (e) {
      state.updateApiErrorMessage(e.message);
      app.deactivateLoader();
    }
  }

  function toggleItem(item: AddonTrain) {
    if (
      state.selectedTrain === null ||
      (!!state.selectedTrain && state.selectedTrain.id !== item.id)
    ) {
      state.removeAllErrors();

      state.selectedItems.forEach(i => workflow.removeItem(i.id));
      state.setTrain(item);

      if (!!state.none && item.id === state.none.id) {
        setTrainToWorkflow(item);
      }
    }
  }

  function isTrainSelected(train: AddonTrain): boolean {
    return !!(state.selectedTrain && state.selectedTrain.id === train.id);
  }

  function onChangeTrainSelection() {
    const train = state.getTrain();
    if (
      !!train &&
      ((!!train.departingSelection && train.departingSelection.hasStepOne) ||
        (!!train.returningSelection && train.returningSelection.hasStepOne))
    ) {
      setTrainToWorkflow(train);
    }
  }

  /* function onChangeTrainConnection() {
    const train = state.getTrain();
    console.log('Current bundles state:', state.bundles); 
    if (
      !!train &&
      ((!!train.departingSelection &&
        train.departingSelection.hasStepOne &&
        train.departingSelection.hasStepTwo) ||
        (!!train.returningSelection &&
          train.returningSelection.hasStepOne &&
          train.returningSelection.hasStepTwo))
    ) {
      setTrainToWorkflow(train);
    } 
  }*/

  // function onChangeTrainDetails() {
  //   const train = state.getTrain();
  //   if (
  //     !!train &&
  //     ((!!train.departingSelection && train.departingSelection.isReadyToBook) ||
  //       (!!train.returningSelection && train.returningSelection.isReadyToBook))
  //   ) {
  //     setTrainToWorkflow(train);
  //   }
  // }

  async function setTrainToWorkflow(item: AddonTrain) {
    app.activateLoader();

    state.removeItemError(item);

    state.selectedItems.forEach(i => workflow.removeItem(i.id));

    workflow.addItem<AddonTrain>(AddonTrain, item.id);

    try {
      await saveStep();
    } catch (e) {
      state.updateApiErrorMessage(e.message);
    }
    app.deactivateLoader();
  }

  async function saveStep() {
    app.trackTimeSaveStarted(workflow);
    state.updateApiErrorMessage(null);

    const payload = new PayloadTrain();
    payload.train = state.getTrain() as AddonTrain;

    if (payload.train) {
      payload.train.destination = state.destinationCode;
      payload.train.returningDeparture = state.returningDepartureCode;
    }

    await workflow.saveStep(payload).then(() => {
      if (state.selectedItems.length > 0) {
        state.setTrain(state.selectedItems[0] as AddonTrain);
      }
    });

    await app.trackTimeSaveFinished(workflow);
  }
});
export default withTheme(RailFly);
